import React from "react";
import {
    CheckoutSection,
    hiddenAnchorStyle,
    OptionListItemRadio,
    labelWithIconStyle,
    iconContainerStyle,
    iconStyle,
} from "../../components";
import { translateResourceString } from "../../../util/translationUtility";
import { Fade } from "../../componentAnimations";
import UseMyAccountComponent from "../ShippingMethod/UseMyAccountComponent";

const CheckoutShipmentPresentation = ({
    shippingOptions,
    selectedId,
    canChange,
    onClickChange,
    onShippingMethodIsValid,
    onSelected,
    isPendingRefresh,
    isCollapsed,
}) => {
    const selectedOption = shippingOptions.find((opt) => opt.Id === selectedId);
    const UseMyAccount = selectedOption ? selectedOption.UseMyAccountComponent : null;

    const shippingOptionIcons = {
        "Standard Shipping": "fas fa-xs fa-truck",
        "Use My Own Shipping Account": "fas fa-xs fa-dolly",
        "Shipping Included": "fas fa-xs fa-check",
    };

    return (
        <CheckoutSection label="Shipping Method" hidden={false}>
            {isPendingRefresh ? (
                <span className="fa fa-spinner fa-spin" />
            ) : (
                <React.Fragment>
                    {shippingOptions.length > 0 && (
                        <div
                            className="d-flex justify-content-between"
                            onClick={(e) => onClickChange(e)}
                            style={{ cursor: "pointer" }}
                        >
                            <span>
                                {!!selectedOption ? selectedOption.NameWithPrice : "Select Shipping Method"}
                            </span>
                            {canChange &&
                                (isCollapsed ? (
                                    <a href="">
                                        <i className="fas fa-caret-down"></i>
                                    </a>
                                ) : (
                                    <a href="">
                                        <i className="fas fa-caret-up"></i>
                                    </a>
                                ))}
                        </div>
                    )}
                    {shippingOptions.length === 0 && (
                        <div>
                            {translateResourceString("/Checkout/ShippingMethod/NoShippingMethodsAvailable")}
                        </div>
                    )}

                    {shippingOptions.length > 0 && (
                        <Fade inProp={!isCollapsed}>
                            <div className="mt-1">
                                <hr />
                                {shippingOptions.map((sm) => {
                                    const isSelected = sm.Id === selectedId;
                                    const iconClass = shippingOptionIcons[sm.Name] || "";
                                    const labelWithIcon = (
                                        <span style={labelWithIconStyle}>
                                            <span style={iconContainerStyle}>
                                                {iconClass && (
                                                    <i
                                                        className={iconClass}
                                                        style={iconStyle}
                                                        aria-hidden="true"
                                                    ></i>
                                                )}
                                            </span>
                                            {sm.NameWithPrice}
                                        </span>
                                    );

                                    return (
                                        <OptionListItemRadio
                                            id={sm.Id}
                                            key={sm.Id}
                                            isSelected={isSelected}
                                            onClick={onSelected}
                                            label={labelWithIcon}
                                            style={hiddenAnchorStyle}
                                        />
                                    );
                                })}
                            </div>
                                {UseMyAccount && (
                                    <div>
                                        <UseMyAccountComponent onShippingMethodIsValid={onShippingMethodIsValid} />
                                    </div>
                                )}
                        </Fade>
                    )}
                </React.Fragment>
            )}
        </CheckoutSection>
    );
};

export default CheckoutShipmentPresentation;
